.footer-link{
  background-color:#F9CEEE;

}
.foot{
  margin-top: 3.75rem;
  margin-left: 1.25rem;
}
p{
  margin: 0px;
}